<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th width="20%" class="text-left pa-0">Name</th>
          <th width="20%" class="text-left">Email</th>
          <th width="20%" class="text-left">Status</th>
          <th width="20%" class="text-left">Added date</th>
          <th width="20%" class="text-left">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td class="text-left pa-0">{{ user.full_name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <p v-if="!user.blocked_at" class="green--text">Active</p>
            <p v-if="user.blocked_at" class="red--text">Blocked</p>
          </td>
          <td>{{ user.addedDate }}</td>
          <td v-show="user.user_type == 'USER'">
            <v-btn
              v-if="!user.blocked_at"
              x-small
              @click="deactivateAccount(user)"
              rounded
              color="grey"
              class="mr-1"
            >
              <strong>Deactivate</strong>
            </v-btn>

            <v-btn
              v-else
              x-small
              rounded
              color="primary"
              class="mr-2"
              @click="activateAccount(user)"
            >
              <strong>Activate</strong>
            </v-btn>

            <v-btn x-small rounded color="error" @click="deleteDialog(user)">
              <strong>Delete</strong>
            </v-btn>
          </td>
        </tr>

        <template v-if="isLoading">
          <tr v-for="index in 10" :key="index + '-skeleton'">
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <confirm-modal
      v-model="blockModalShow"
      :title="
        user && user.blocked_at ? 'Activate Account' : 'Deactivate Account'
      "
      :message="contentMsg"
      :is-loading="confirmLoading"
      @cancel="blockModalShow = false"
      @confirm="blockAccount"
    ></confirm-modal>
    <confirm-modal-delete
      v-model="deleteModalShow"
      title="Delete Account"
      message="Are you sure you want delete this account?"
      :is-loading="confirmLoading"
      @confirm="deleteAccount"
      @cancel="deleteModalShow = false"
    >
    </confirm-modal-delete>
  </div>
</template>
<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import ConfirmModalDelete from '@/components/modals/ConfirmModalDelete'
export default {
  name: 'SimpleTable',
  components: {
    ConfirmModal,
    ConfirmModalDelete,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blockModalShow: false,
      deleteModalShow: false,
      actionMsg: '',
      form: null,
      user: null,
      contentMsg: '',
      confirmLoading: false,
      confirmTitle: '',
    }
  },
  methods: {
    deactivateAccount(value) {
      this.blockModalShow = true
      this.user = value
      this.contentMsg = `Are you sure you want deactivate this account?`
    },

    activateAccount(user) {
      this.blockModalShow = true
      this.user = user
      this.contentMsg = `Are you sure you want activate this account?`
    },

    deleteDialog(user) {
      this.deleteModalShow = true
      this.user = user
    },

    deleteAccount() {
      this.confirmLoading = true
      this.user.delete().then(() => {
        this.confirmLoading = false
        this.deleteModalShow = false
        this.$store.dispatch(
          'showSuccessSnackbar',
          'User deleted successfully!'
        )
        this.$emit('success')
        this.user = null
      })
    },

    blockAccount() {
      this.confirmLoading = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'

      this.$store
        .dispatch('user/blockUser', {
          id: this.user.id,
          action,
        })
        .then(() => {
          this.confirmLoading = false
          this.blockModalShow = false
          let msg = this.user.blocked_at ? 'activated' : 'deactivated'
          this.$store.dispatch(
            'showSuccessSnackbar',
            `User account successfully ${msg}!`
          )
          this.user = null
          this.$emit('success')
        })
        .catch(() => {
          this.confirmLoading = false
          this.$store.dispatch(
            'showErrorSnackbar',
            'Something went wrong. Please try again.'
          )
        })
    },
  },
}
</script>
