<template>
  <div class="filter-container">
    <v-row v-show="showSearch" align="center" style="max-width: 300px">
      <v-text-field
        class="search"
        ref="searchField"
        flat
        hide-details
        solo
        rounded
        clearable
        background-color="grey lighten-3"
        :label="placeHolder"
        v-model="filter.search"
        @input="$emit('search')"
      ></v-text-field>
    </v-row>
    <template>
      <v-select
        class="ml-10"
        hide-details
        dense
        item-text="label"
        item-value="value"
        v-model="defaultSort"
        :items="filterBy"
        style="max-width: 200px"
        @change="filterEvent"
      />

      <v-btn
        depressed
        class="ml-2"
        :color="sortOrder ? 'primary' : ''"
        @click="
          sortOrder = true
          filterEvent(sortValue)
        "
      >
        <v-icon>{{ icons.arrowUp }}</v-icon>
      </v-btn>
      <v-btn
        depressed
        class="ml-2"
        :color="!sortOrder ? 'primary' : ''"
        @click="
          sortOrder = false
          filterEvent(sortValue)
        "
      >
        <v-icon>{{ icons.arrowDown }}</v-icon>
      </v-btn>

      <v-btn class="ml-5" icon @click="showSearchField">
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'
export default {
  names: 'Filter',

  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterBy: { type: Array, required: true },
    defaultFilter: {
      type: String,
      default: 'name',
    },
    placeHolder: {
      type: String,
      default: '',
    },
  },

  computed: {
    defaultSort: {
      get() {
        return this.defaultFilter
      },
      set(value) {
        return value
      },
    },
  },

  watch: {
    filter() {
      this.$emit('input', this.filter.search)
    },
  },

  data() {
    return {
      showSearch: false,
      icons: {
        arrowDown: mdiArrowDown,
        arrowUp: mdiArrowUp,
      },
      sortOrder: true,
      sortValue: this.defaultFilter,
    }
  },

  methods: {
    filterEvent(value) {
      this.sortValue = value
      this.$emit(
        'filter-event',
        this.sortOrder ? `${this.sortValue}` : `-${this.sortValue}`
      )
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: contents;
}
</style>
