<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        v-if="isLoading"
        type="chip"
        transition="fade-transition"
      />

      <v-toolbar-title v-else class="headline font-weight-bold">
        Users ({{ listMeta.total }})
      </v-toolbar-title>

      <v-spacer />
      <common-filter
        :filter-by="filterBy"
        :filter="filter"
        :default-filter="defaultFilter"
        place-holder="Search name"
        @search="search"
        @filter-event="filterUser"
      ></common-filter>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            color="#383d3f"
            class="mr-1"
            icon
            @click="exportList"
            v-on="on"
          >
            <v-icon>{{ icons.export }}</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <simple-table
        :users="users"
        :is-loading="isLoading"
        @success="reloadList"
      ></simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(users.length > 0) && !isLoading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiChevronRight,
} from '@mdi/js'
import { mapState, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import SimpleTable from './components/SimpleTable.vue'
import CommonFilter from '@/components/filters/CommonFilter.vue'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    SimpleTable,
    CommonFilter,
  },

  data() {
    return {
      isLoading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
      },
      defaultFilter: 'full_name',
      sortOrderBy: 'full_name',
      filterBy: [
        {
          value: 'full_name',
          label: 'Name',
        },
        {
          value: 'email',
          label: 'Email',
        },
        {
          value: 'blocked_at',
          label: 'Status',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      icons: {
        export: mdiFileExcel,
        arrow: mdiChevronRight,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
    }
  },

  created() {
    this.clearUsers()
    this.fetchUsers(1)
  },

  computed: {
    ...mapState({
      users: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapMutations({
      clearUsers: 'user/clearUserList',
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    async fetchUsers(page = 1) {
      if (this.isLoading) return
      let params = {
        page,
        sort: this.sortOrderBy,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.isLoading = true
      this.$store.dispatch('user/getUsers', params).then(() => {
        this.isLoading = false
      })
    },

    reloadList() {
      this.$store.commit('user/clearUserList')
      this.fetchUsers()
    },

    filterUser(filterValue) {
      this.sortOrderBy = filterValue
      this.$store.commit('user/clearUserList')
      this.fetchUsers()
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchUsers()
    }, 600),

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async exportList() {
      this.isLoading = true
      await this.$store
        .dispatch('user/exportToExcel')
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users.xlsx')
          document.body.appendChild(link)
          link.click()
          this.$store.dispatch(
            'showSuccessSnackbar',
            'File successfully downloaded'
          )
        })
        .catch(() => {
          this.$store.dispatch('showErrorSnackbar', 'Ops! Something went wrong')
        })
      this.isLoading = false
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
